import React, { MouseEventHandler } from 'react';
import Link from 'next/link';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';

import Image from 'Components/common/image';

import { strings } from 'Constants/strings';

import colors from 'Static/typography/colors';
import TYPE_LABELS from 'Static/typography/labels';

const CityDisplayName = styled.div`
	&.city-display-name {
		color: ${colors.GREY['44']};
		${getFontDetailsByLabel(TYPE_LABELS.HEADING_PRODUCT_CARD)};
	}
`;

const CityCountryName = styled.span`
	&.city-country-name {
		color: ${colors.GREY['66']};
		${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_REGULAR)};
	}
`;
const CityCardLink = styled.a`
	&.city-card-link-wrapper {
		width: 11.25rem;
		min-height: 16.25rem;
		margin: 0.625rem 1.5rem 1.5rem 0;
	}
`;

type Props = {
	city?: any;
	className?: string;
	clickUrl?: string;
	pageHref?: string;
	onClick?: MouseEventHandler<HTMLAnchorElement>;
	showDiscoverableOnly?: boolean;
};

class CityCard extends React.PureComponent<Props> {
	getCityCard() {
		const {
			city,
			className,
			pageHref,
			onClick,
			showDiscoverableOnly = true,
		} = this.props;
		if (!city || (showDiscoverableOnly && !city?.discoverable)) return null;
		const {
			imageURL,
			displayName,
			country: { displayName: countryDisplayName },
		} = city;
		return (
			<Link
				prefetch={false}
				href={pageHref ?? ''}
				passHref
				legacyBehavior
			>
				<CityCardLink
					onClick={onClick}
					className='city-card-link-wrapper block'
				>
					<div className={`city-card-v2-wrapper ${className || ''}`}>
						<div className='city-card'>
							<div className='city-image-wrapper'>
								<Image
									className='city-image'
									src={imageURL}
									width={180}
									height={180}
									alt={displayName}
								/>
							</div>
							<div className='city-bottom-wrapper'>
								<div className='city-title'>
									<CityDisplayName
										className={'city-display-name'}
									>
										{strings.formatString(
											strings.THINGS_TO_DO_IN_CITY,
											displayName,
										)}
									</CityDisplayName>
								</div>
								<div className='city-tag-line'>
									<CityCountryName
										className={'city-country-name'}
									>
										{countryDisplayName}
									</CityCountryName>
								</div>
							</div>
						</div>
					</div>
				</CityCardLink>
			</Link>
		);
	}

	render() {
		return this.getCityCard();
	}
}

export default CityCard;
